.FooterGrey{
    /*background: rgba(0,0,0,0.05)*/
}


#root .FooterGrey .ui.menu.vertical{
    width: 100%;
}


@media( min-width:480px){
    .FooterGrey .ui.menu.vertical{
        display:flex;
        flex-direction: row;
    }

    #root .FooterGrey .ui.menu.vertical .item{
        padding:0.5rem 1rem;
    }
}
