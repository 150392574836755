#ncg-app .AddTiresToCart input[type=number]::-webkit-inner-spin-button,
#ncg-app .AddTiresToCart input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

#ncg-app .AddTiresToCart input[type=number]{
    text-align:center;
}


#ncg-app .AddTiresToCart div.ui.selection.dropdown {
    min-width: 6em;
}


.ui.active.selection.dropdown{
    z-index:99;
}
