
.ShopBreadcrumb{

}

.ShopBreadcrumb .scroll{
    white-space: nowrap; /* [1] */
    overflow-x: auto; /* [2] */
    -webkit-overflow-scrolling: touch; /* [3] */
    -ms-overflow-style: -ms-autohiding-scrollbar; /* [4] */
}

.ShopBreadcrumb .scroll::-webkit-scrollbar {
    display: none;
}

.ShopBreadcrumb .BreadcrumbFragment{
}

.ShopBreadcrumb .BreadcrumbFragment a,
.ShopBreadcrumb .BreadcrumbFragment *{
    transition: color 320ms ease-out;
}




.ShopBreadcrumb .BreadcrumbFragment a:hover,
.ShopBreadcrumb .BreadcrumbFragment a:focus,
.ShopBreadcrumb .BreadcrumbFragment a:active{
}

.ShopBreadcrumb .BreadcrumbFragment:last-child a,
.ShopBreadcrumb .BreadcrumbFragment:last-child {
    font-weight: bold;
    /*color: rgba(0,0,0,1);*/
}

@media(min-width: 768px){

    .ShopBreadcrumb .BreadcrumbFragment a{
        color: white;
    }
}

@keyframes pulse-black {
    0% {
        transform: scale(1);
        box-shadow: 0 0 0 0 hsla(210, 53%, 51%, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px hsla(210, 53%, 51%, 0);
    }

    100% {
        transform: scale(1);
        box-shadow: 0 0 0 0 hsla(210, 53%, 51%, 0);
    }
}
