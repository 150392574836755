@tailwind base;
@tailwind components;
@tailwind utilities;


html body {
    margin: 0;
    padding: 0;
    height: auto;
    overflow-y: scroll;
    overflow-x: hidden;
    line-height: 1.4em;
    background: #ededf0;
    min-height: 100%;
}

:root {
    --color-primary: #415b8c;
}

#root form input[type="email"] {
    text-transform: lowercase;
}

#root form input[type="email"]::placeholder {
    text-transform: none;
}

#root .QuickorderInput input{
    font-size:1.2em;
    border-radius: 8px !important;
    background:white
}

#root .QuickorderInput input:focus{
   background: #f0f0f5;
}

.Toastify{
    z-index:9999999;
    position:fixed;
}

.link-underline {
    border-bottom-width: 0;
    background-image: linear-gradient(transparent, transparent), linear-gradient(#fff, #fff);
    background-size: 0 3px;
    background-position: 0 100%;
    background-repeat: no-repeat;
    transition: background-size .5s ease-in-out;
}

.link-underline-black {
    background-image: linear-gradient(transparent, transparent), linear-gradient(#F2C, #F2C)
}

.link-underline:hover {
    background-size: 100% 3px;
    background-position: 0 100%
}



.scrolling-wrapper {
    -webkit-overflow-scrolling: touch;
}

.scrolling-wrapper::-webkit-scrollbar {
    display: none;
}

.shadow-lg {
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.1) 0px 4px 6px -4px;
}

.bg-blue-10 {
    background: rgba(26, 31, 113, 0.1);
}

.scale-120 {
    transform: scale(1.25);
}

.hover-scale-1:hover {
    transform: scale(1);
}


.blend-multiply {
    mix-blend-mode: multiply;
}


.hide-child .hide-on-hover {
    transition-duration: 350ms;
}

.hide-child:hover .hide-on-hover,
.hide-child:focus .hide-on-hover,
.hide-child:active .hide-on-hover {
    opacity: 0;
}

.box-shadow-soft {
    box-shadow: 0 6px 23px 0 rgba(0, 0, 0, 0.16);
}


.box-shadow-softer {
    box-shadow: 0 2px 12px -4px rgba(0, 0, 0, 0.12);
}


.flip-x {
    transform: scaleX(-1)
}

.ui.form .ui.dropdown {
    padding: 1.12362637em 1.07692308em;
    background: rgb(245, 245, 245);
}

.hyphens-auto {
    hyphens: auto;
    hyphenate-limit-chars: 6 3 2;
}

#root .ui.fluid.search > div {
    width: 100%
}


.cursor-not-allowed {
    cursor: not-allowed;
}

.ui.button.small {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
}

/* SHOW CMS SOURCES IF #root is in the url*/
#root:target *[data-src]::before {
    z-index: 9;
    display: block;
    line-height: 100%;
    border-radius: 4px;
    padding: 2px 12px;
    background: red;
    color: white;
    position: absolute;
    font-size: 12px;
    content: attr(data-src);
    opacity: 0.8;
}

.aspect-40 {
    padding-bottom: 40%;
}

.ml-auto {
    margin-left: auto;
}

.mh-2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
}

.mr-auto {
    margin-right: auto;
}


.circular.perfect {
    width: 1.25rem;
    height: 1.25rem;
    padding: 0 !important;
}

#root .ui.selection.dropdown .menu > .item {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
    color: #424249;
}

#root .ui.dropdown .menu .active.item {
}

#root .ui.selection.dropdown .menu {
}

#root .ui.selection.active.dropdown .menu {
    @apply rounded-md shadow-md;
    top:0;
    margin-top:-1px;
    margin-left:-5px;
    margin-right:-5px;
    right:0;
    left:0;
    width:calc(100% + 10px);
    border:0;
}

#root .fa-secondary-o-10 .fa-secondary {
    transition: opacity 620ms ease-out;
    opacity: 40%;
}


#root a:hover .fa-secondary-o-10 .fa-secondary {
    opacity: 20%;
}


.ui.form .ui.input input:required:invalid {
    border-bottom: 2px solid red;
}

.ui.button.mr0 {
    margin-right: 0;
}

.text-promo {
    font-size: 20px;
}

img.filter-grayscale {
    filter: grayscale(100%);
}

img.filter-inverted {
    filter: invert();
}

#TrustedShopHeader {
    display: flex;
}

img.ghosted {
    opacity: 0.3;
}

@media only screen and (max-width: 1199px) and (min-width: 992px) {
    #root .ui.container {
        width: 96vw !important;
    }


}


.page-min-height {
    min-height: calc(100vh - 150px);
}

@media only screen and (max-width: 1440px) {
    #root .ui.container {
        width: 100%;
        width: calc(100% - 4rem);
    }
}

@media only screen and (min-width: 1280px) {


    .transform-scale-1 {
        transform: scale(1.1);
    }

    .page-min-height {
        min-height: calc(100vh);
    }
}

@media only screen and (max-width: 767px) {

    #root .ui.modal > .header {
        padding: 0.75rem 1rem !important;
    }
}


@media only screen and (max-width: 420px) {
    #root .slick-slider.Slick-Center .slick-list {
        padding: 0 10% 0 0 !important;
    }


}


@media only screen and (min-width: 1400px) {
    #root .SidebarLink .svg-icon {
        width: 17px !important;
        height: 17px !important;
        margin-left: 0px;
    }

    #root .SidebarLink span.cat-H35.icon {
        margin-right: 12px !important;
    }

    .page-min-height {
        min-height: calc(100vh);
    }

    body, html {
        font-size: 15px !important;
    }


}

@media only screen and (min-width: 1600px) {
    body, html {
        font-size: 16px !important;
    }


}


#root .sticky-when-scrolled.mobile {
    display: none;
}


#root.has-scrolled .sticky-when-scrolled {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 12;
}

.mt-auto {
    margin-top: auto;
}

.mb-auto {
    margin-bottom: auto;
}

.outline-border {
    border: 1px solid rgb(207, 203, 202);
}

.ui.input.text-center input {
    text-align: center;
}

#ncg-app {
    background: #f9f9f9;
    min-height: 100%;
}

.ui input::placeholder {
    color: rgba(0, 0, 0, 0.4) !important;
}

.font-hind {
    font-family: "Hind", "open sans", sans-serif;
}

.font-plex {
    font-family: 'IBM Plex Sans', sans-serif;
}

.ellipsis {
    text-overflow: ellipsis;
}

.bg-blue-dark {
    background: #1d212d;
}

.bg-blue-darkest {
    background: #090a0f;
}

.bg-blue-lighter {
    background: #2c3550;
}


#root .loading .animated {
    animation-duration: 0ms;
}


#root .ui.segments {
    border: none;
}

#root .ui.segment {
    @apply rounded-xl shadow-sm !important;
    border: 1px solid #f0f0f5;
}

#root .ui.card {
    @apply rounded !important;
}

#root .ui.card.active {
    border: 2px solid #001b44;
}

#root .ColorButton.tiny {
}

#root .ColorButton.active {
    box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 1) !important;
}

#root .ColorButton:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 50%;
    border-radius: 0;
    background: rgba(255, 255, 255, 0.15);
    opacity: 1;
    transition: all 420ms ease-out;
}

#root .ColorButton {
    overflow: hidden;
    border: none !important;
    height: 20px;
    width: 50px;
    padding: 0 !important;
    margin: 0 3px;
    position: relative;
    box-shadow: 0 2px 3px -1px rgba(0, 0, 0, 0.4) !important;
}

#root .ColorButton:hover:after {
}

.ColorButton.active:after {
    opacity: 1;
}

#root .ui.card .content {
    height: 100%;
    transition: all 0.2s ease-out;
    transition: all 0.2s cubic-bezier(0.4, 0, 1, 1)
}

#root .ui.card .content:hover {
    /*box-shadow: 0 7px 9px 0 rgba(0, 0, 0, 0.25) !important*/
}

#root .ui.card {
    transition: all 250ms ease-out;
}

#root .ui.card:hover {
    border-radius: 0;
    border: 1px solid transparent;
    box-shadow: 0 0 8px -2px rgba(0, 0, 0, 0.45);
}

.ui.button:hover {
}

body .ui.inverted.page.modals.dimmer.transition.visible.active {
}

body .ui.fullscreen.modal {
    width: 100% !important;
    margin: 0 !important;
    box-shadow: none !important;
    background: transparent;
    overflow-y: auto;
}

#root .ui.header {
    font-weight: normal;
}

#root .StorePage .ui.button.basic {
}

#root input[type=text] {
}

.pre-line {
    white-space: pre-line;
}

.user-select-none {
    -webkit-user-select: none; /* Chrome all / Safari all */
    -moz-user-select: none; /* Firefox all */
    -ms-user-select: none; /* IE 10+ */
    user-select: none; /* Likely future */
}

.shadow-top {
    box-shadow: 0 0 9px 1px rgba(0, 0, 0, 0.25);
}

.page-wrapper {
    background: #f9f9f9;
    width: 100%;
    max-width: 100%;
}

.ba {
    border-color: #b0b0b9;
}

#ncg-app .ui.inverted.menu {
    background-color: #202638;
}

.ui.button:focus {
    box-shadow: inset 0 0 6px 1px rgba(18, 42, 62, 0.12) !important;
    border: 0 solid #001b4450;
}

a.ui:focus {
    box-shadow: inset 0 0 5px 1px #001b4450;
    border: 0 solid #001b4450;
}


#root .RegistrationBox input:required + i.icon {
    display: none;
}

#root .RegistrationBox input:required:valid + i.icon {
    display: block;
}

.RegistrationBox i.icon {
    color: green;
}

#root .segment {
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
    border: 0;
}

#root .segment.inverted {
    background-color: #212738;
}

#root .ui.button.primary.inverted {
    background: white;
    border: 0;
    box-shadow: none !important;
    color: #323232;
    padding-left: 26px;
    padding-right: 26px;
}

#root .ui.button.primary.inverted:hover {
    background: #efefef;
}

#root .ui.cards > .card, .ui.card {
    border-radius: 0;
}

#root .ui.three.cards > .card {
}

.segment {
    padding: 16px 32px;
}

.border-top {
    border-top: 1px solid #efefef;
}

.border-bottom {
    border-bottom: 1px solid #efefef;
}

/*#root .bg-blue {*/
/*    background: #212738;*/
/*}*/
#root .bg-blue {
    background: #202638;
}

#root .bg-gradient {
    background: linear-gradient(90deg, #001b44, #1e2744);
}

body .bg-gradient-vertical {
    background: linear-gradient(-13deg, #001b44, #1e2744);
}

#root .shadow {
    box-shadow: 10px 10px 75px -14px rgba(0, 0, 0, 0.75);
}

#root .shadow-raised {
    box-shadow: 0 40px 154px -40px rgba(0, 0, 0, 0.2)
}


/*MODAL ANIMATION */
.modals.active .ui.modal.transition {
    @apply rounded-xl;
    animation-duration: 500ms;
    animation-delay: 50ms;
    animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
}


.modals.active .ui.modal.transition .header{
    @apply rounded-t-xl;
}

@keyframes modalFadeIn {
    from {
        background-color: red;
    }
    to {
        background-color: yellow;
    }
}

@media (max-width: 420px) {
    #root .ui.container .ui.stackable.grid .row .column {
        margin: 0;
        padding-top: 0 !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    #root .group-selection .column {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

}

.bg-darken {
    background: rgba(0, 0, 0, 0.1)
}

.bg-darken-2 {
    background: rgba(0, 0, 0, 0.2)
}

.bg-darken-4 {
    background: rgba(0, 0, 0, 0.4)
}

.bg-darken-6 {
    background: rgba(0, 0, 0, 0.6)
}

.vignette {
    position: relative;
}


.vignette:after {
    pointer-events: none;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    box-shadow: inset 0px 0px 200px rgba(0, 0, 0, 0.25);
}

.vignette-dark:after {
    pointer-events: none;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    box-shadow: inset 0px 0px 120px rgba(0, 0, 0, 0.8);
}

html body #LeadboosterContainer {
    z-index: -1 !important;
}

.ui.large.buttons .button, .ui.large.buttons .or, .ui.large.button {
    padding-left: 42px;
    padding-right: 42px;
}

.ui.inverted.dimmer {
    background: rgba(255, 255, 255, 0.9);
}

.ui.inverted.dimmer > .ui.modal {
    box-shadow: 0 14px 32px -3px rgba(0, 0, 0, 0.20);
}

body .ui.button {
    font-weight: normal !important;
}

body .ui.menu a,
body .ui.button {
    font-family: "IBM Plex Sans", sans-serif !important;
}

body .ui.section.header {
    font-weight: bold;
    color: #888;
}

.ui.primary.button:hover, .ui.primary.buttons .button:hover {
    background: #001b44;
    color: white;
}

.ui.inverted.button {
    background: white;
    color: #001b44;
}

body .work-package.icon {
    color: silver;
    margin: 0 0 0 0.1rem;
    position: relative;
    top: 1px;
}

.f6.ui.checkbox label {
    font-size: .875rem;
    line-height: 1.4;
}

body .work-package.icon:hover {
    cursor: pointer;
}

body .clamped-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-height: 1.25;
    height: 2.5em;
    font-weight: bold
}

button {
    padding: 0;
    font-family: "IBM Plex Sans", sans-serif;
}

button.text {
    border: none;
    background: transparent;
    color: #4183C4;
    cursor: pointer;
    transition: color 340ms ease-out;
}

button.text:hover {
    color: #16518b;
}

button.text:focus {
    outline: none;
    text-decoration: underline;
    color: #16518b;
}

.ui.form .field > label {
    font-weight: 400;
}

body .ui.button.primary.large.fluid {
    padding: 16px 24px;
    font-weight: 300 !important;
}

.ui.primary.buttons .button:focus, .ui.primary.button:focus {
    background: #001b44;
}

#root .ui.basic.buttons .button:focus,
#root .ui.basic.button:focus {
    background: #efefef !important;
    color: black;
}

#root .ui.button.inverted.basic:focus {
    background: #242424 !important;
    box-shadow: inset 0 0 0 1px #efefef !important;
}

i.circular.icon {
    box-shadow: none !important;
}

@media (max-width: 767px) {
    .ui.four.doubling.cards > .card {
        width: calc(50% - 0.5em);
        margin: 0.25em;
    }
}

.ui.breadcrumb a {
    color: #454555;
    font-size: 13px;
}

.f0 {
    font-size: 3.15rem;
}

a {
    color: #5F85AF;
}

a:hover {
    color: #7ca4ce;
}


/*ALSO A FIX FOR FF Flexbox */
@-moz-document url-prefix() {
    .ui.cards {
        width: 100%;
    }
}


.bg-blue-gradient {
    background: linear-gradient(90deg, #292a30, #424f6f);
}


.bg-ncg-black {
    background: rgb(35, 35, 35);
}

.scroll {
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: visible;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; /* [4] */
}


.lh-list {
    line-height: 2;
}

/* [5] */
.scroll::-webkit-scrollbar {
    display: none;
}


.ui.basic.primary.buttons .button:focus, .ui.basic.primary.button:focus {
    box-shadow: 0px 0px 0px 2px #415b8c inset !important;
    color: #415b8c !important;
}

.ui.primary.buttons .button:focus, .ui.primary.button:focus {
    background: #415b8c !important;
}

.ui.basic.primary.buttons .button, .ui.basic.primary.button {
    color: #1E2744 !important;
    box-shadow: 0px 0px 0px 1px #1E2744 inset !important;
}


#root .brands {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

#root .brands .brand img {
    height: 48px !important;
    width: auto;
    margin: 0.3em 0.3em;
    filter: invert(1)
}

.ui.cards.w-100 {
    width: auto !important;
    clear: both;
}

@-moz-document url-prefix() {
    .ui.cards.w-100 {
        width: 100% !important;
    }
}


@media (max-width: 768px) {
    #root .f0 {
        font-size: 3rem;
    }


}


@media (max-width: 490px) {

    #root .ui.page.modals.transition.visible {
        animation: fadeIn ease-out 250ms;
        overflow: hidden !important;
    }

    #root .ContentWrapper {
        transition: transform 250ms ease-out;

    }

    #root.dimmable.dimmed:has(.ui.page.modals) .ContentWrapper {
        transform: translateX(-45px);
    }

    .ui.modal.fullscreen {
        animation: fadeInRight ease-out 1200ms;
        animation-fill-mode: forwards;
        animation-delay: 250ms;
        opacity: 0;
        background: white !important;
        width: 100% !important;
        height: 100dvh !important;
        margin: 0 !important;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
        display: flex !important;

        flex-direction: column;
        flex-wrap: nowrap;
    }


    .ui.modal.fullscreen > .content {
        overflow-y: auto;
    }


    .ui.modal.fullscreen > .actions {
        margin-top: auto;
    }


    #root .f0 {
        font-size: 2.5rem;
    }

    #root .f1 {
        font-size: 2.2rem;
    }

    #root .footer-menu .ui.menu {
        display: flex;
        justify-content: center;
    }

    #root .brands .brand-homelink {
        width: 62px;
    }

    #root .brands .brand img {
        height: 35px !important;
    }

    #root .TeaserCompact .pa4 {
        padding: 1rem;
    }

    #root .TeaserCompact .f3 {
        font-size: 18px;
    }

    #root .TeaserCompact .f2 {
        font-size: 20px;
    }
}

.bottom-drawer {
    padding: 0.5rem;
    border-top: 1px solid #efefef;
    box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.25);
    background: white;
}

.fa-secondary-opacity .fa-secondary {
    opacity: 0;
}

.fa-primary-opacity .fa-primary {
    opacity: 0;
}

#root .ui.fluid.upward.dropdown.active + .title {
    opacity: 1;
    transition: opacity cubic-bezier(0.19, 1.16, 1, 1) 320ms;
}

#root .ui.fluid.upward.dropdown.active + .title {
    opacity: 0;
}

.transition-color {
    transition: color 0.25s ease-out;
}

#root .GroupTitleCard .ui.image,
#root .CarCard .ui.image {
    height: 100% !important;
}

.ui.image.h-100 {
    height: 100% !important;
}

#root .Toastify__toast {
    background: #040406;
    @apply rounded-xl pl-4;
}

#root .Toastify__toast--error {
    background: #b80000;
}

#root .Toastify__toast--warning {
    background: #b87500;
}

#root .Toastify__toast--success {
    background: #74bc0c;
}

.fadeInTo30 {
    animation: fadeIn30 ease-out 1200ms;
}

@keyframes fadeIn30 {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 0.3
    }
}

.line-clamp-1,
.line-clamp-2,
.line-clamp-3,
.line-clamp-4 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.line-clamp-1 {
    -webkit-line-clamp: 1;
}

.line-clamp-2 {
    -webkit-line-clamp: 2;
}

.line-clamp-3 {
    -webkit-line-clamp: 3;
}

.line-clamp-4 {
    -webkit-line-clamp: 4;
}

#root .has-subcategory-indicators .card > .content, .ui.card > .content {
    border: 1px solid transparent !important; /*fixes ghost shadow of semantic cards on hover*/
}

#root .has-subcategory-indicators .card {
    margin-top: calc(0.875em + 2rem) !important; /* we can't overwrite a semantic ui card's margin-top with tachyons :/ */
}

.VehicleTypeButton {
    transition: all 420ms ease-out;
    border: 1px solid rgba(34, 36, 38, .15);
    border-bottom: 4px solid rgba(34, 36, 38, .15)
}

.VehicleTypeButton.active {
    border: 1px solid transparent;
    border-bottom: 4px solid var(--color-primary);
    background: white;
}


#root .ui.button.DropdownButton {
    cursor: pointer;
    word-wrap: break-word;
    line-height: 1em;
    white-space: normal;
    outline: 0;
    transform: rotate(0deg);
    min-width: 14em;
    min-height: 3.5989011em;
    background: #f5f5f5 !important;
    display: inline-block;
    padding: 1.23076923em 2.17692308em 1.23076923em 1.07692308em;
    color: #434349;
    box-shadow: none;
    border: 1px solid rgba(34, 36, 38, .15);
    border-radius: 0;
    transition: box-shadow .1s ease, width .1s ease;
}

.tracked-narrow {
    letter-spacing: -0.015em;
}

.quicksearch-comment input {
    padding: 0.5em !important;
}


