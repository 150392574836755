.ui.card > .image, .ui.cards > .card > .image {
    background: none;
}
.ui.blue.buttons .button:hover, .ui.blue.button:hover,
.ui.primary.buttons .button:hover, .ui.primary.button:hover{
    background-color: #16518b;
}
.ui.card, .ui.cards > .card {
    box-shadow: none;
    border: 1px solid #DDD;
    transition: background-color 0.15s ease-in, border-color 0.15s ease-in;
}

.ui.cards a.card:hover,
.ui.link.card:hover,
.ui.link.cards .card:hover,
a.ui.card:hover,
.ui.card.in-cart {
    transform: translateY(0);
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .14);
    border: 1px solid #efefef;
}


.box-shadow-light {
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.14)
}

.box-shadow {
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.34)
}

.box-shadow-raised {
    box-shadow: 0 1px 18px 0 rgba(0, 0, 0, .34);
}


.bg-silver {
    background: #edeef0;
}


#root .ui.card .content{
    /*height:100%;*/
}

.container.flex {
    display: flex;
}

.ui {
    font-family: "IBM Plex Sans", sans-serif !important;
}

.ui.loader {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 0px;
    text-align: center;
    z-index: 1000;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}

.ui.loader:before {
    position: absolute;
    content: '';
    top: 0%;
    left: 50%;
    width: 100%;
    height: 100%;
    border-radius: 500rem;
    border: 0.2em solid rgba(0, 0, 0, 0.1);
}

.ui.loader:after {
    position: absolute;
    content: '';
    top: 0%;
    left: 50%;
    width: 100%;
    height: 100%;
    -webkit-animation: loader 0.6s linear;
    animation: loader 0.6s linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    border-radius: 500rem;
    border-color: #767676 transparent transparent;
    border-style: solid;
    border-width: 0.2em;
    -webkit-box-shadow: 0px 0px 0px 1px transparent;
    box-shadow: 0px 0px 0px 1px transparent;
}

/* Active Animation */
@-webkit-keyframes loader {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes loader {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

/* Sizes */
.ui.mini.loader:before,
.ui.mini.loader:after {
    width: 1rem;
    height: 1rem;
    margin: 0em 0em 0em -0.5rem;
}
.ui.tiny.loader:before,
.ui.tiny.loader:after {
    width: 1.14285714rem;
    height: 1.14285714rem;
    margin: 0em 0em 0em -0.57142857rem;
}
.ui.small.loader:before,
.ui.small.loader:after {
    width: 1.71428571rem;
    height: 1.71428571rem;
    margin: 0em 0em 0em -0.85714286rem;
}
.ui.loader:before,
.ui.loader:after {
    width: 2.28571429rem;
    height: 2.28571429rem;
    margin: 0em 0em 0em -1.14285714rem;
}
.ui.large.loader:before,
.ui.large.loader:after {
    width: 3.42857143rem;
    height: 3.42857143rem;
    margin: 0em 0em 0em -1.71428571rem;
}
.ui.big.loader:before,
.ui.big.loader:after {
    width: 3.71428571rem;
    height: 3.71428571rem;
    margin: 0em 0em 0em -1.85714286rem;
}
.ui.huge.loader:before,
.ui.huge.loader:after {
    width: 4.14285714rem;
    height: 4.14285714rem;
    margin: 0em 0em 0em -2.07142857rem;
}
.ui.massive.loader:before,
.ui.massive.loader:after {
    width: 4.57142857rem;
    height: 4.57142857rem;
    margin: 0em 0em 0em -2.28571429rem;
}

.ui.dimmer .loader {
    display: block;
}

.ui.dimmer .ui.loader {
    color: rgba(255, 255, 255, 0.9);
}
.ui.dimmer .ui.loader:before {
    border-color: rgba(255, 255, 255, 0.15);
}
.ui.dimmer .ui.loader:after {
    border-color: #FFFFFF transparent transparent;
}

.ui.inverted.dimmer .ui.loader {
    color: rgba(0, 0, 0, 0.87);
}
.ui.inverted.dimmer .ui.loader:before {
    border-color: rgba(0, 0, 0, 0.1);
}
.ui.inverted.dimmer .ui.loader:after {
    border-color: #767676 transparent transparent;
}

.ui.text.loader {
    width: auto !important;
    height: auto !important;
    text-align: center;
    font-style: normal;
}

.ui.indeterminate.loader:after {
    animation-direction: reverse;
    -webkit-animation-duration: 1.2s;
    animation-duration: 1.2s;
}
.ui.loader.active,
.ui.loader.visible {
    display: block;
}
.ui.loader.disabled,
.ui.loader.hidden {
    display: none;
}

.ui.inverted.dimmer .ui.mini.loader,
.ui.mini.loader {
    width: 1rem;
    height: 1rem;
    font-size: 0.78571429em;
}
.ui.inverted.dimmer .ui.tiny.loader,
.ui.tiny.loader {
    width: 1.14285714rem;
    height: 1.14285714rem;
    font-size: 0.85714286em;
}
.ui.inverted.dimmer .ui.small.loader,
.ui.small.loader {
    width: 1.71428571rem;
    height: 1.71428571rem;
    font-size: 0.92857143em;
}
.ui.inverted.dimmer .ui.loader,
.ui.loader {
    width: 2.28571429rem;
    height: 2.28571429rem;
    font-size: 1em;
}
.ui.inverted.dimmer .ui.large.loader,
.ui.large.loader {
    width: 3.42857143rem;
    height: 3.42857143rem;
    font-size: 1.14285714em;
}
.ui.inverted.dimmer .ui.big.loader,
.ui.big.loader {
    width: 3.71428571rem;
    height: 3.71428571rem;
    font-size: 1.28571429em;
}
.ui.inverted.dimmer .ui.huge.loader,
.ui.huge.loader {
    width: 4.14285714rem;
    height: 4.14285714rem;
    font-size: 1.42857143em;
}
.ui.inverted.dimmer .ui.massive.loader,
.ui.massive.loader {
    width: 4.57142857rem;
    height: 4.57142857rem;
    font-size: 1.71428571em;
}

.ui.mini.text.loader {
    min-width: 1rem;
    padding-top: 1.78571429rem;
}
.ui.tiny.text.loader {
    min-width: 1.14285714rem;
    padding-top: 1.92857143rem;
}
.ui.small.text.loader {
    min-width: 1.71428571rem;
    padding-top: 2.5rem;
}
.ui.text.loader {
    min-width: 2.28571429rem;
    padding-top: 3.07142857rem;
}
.ui.large.text.loader {
    min-width: 3.42857143rem;
    padding-top: 4.21428571rem;
}
.ui.big.text.loader {
    min-width: 3.71428571rem;
    padding-top: 4.5rem;
}
.ui.huge.text.loader {
    min-width: 4.14285714rem;
    padding-top: 4.92857143rem;
}
.ui.massive.text.loader {
    min-width: 4.57142857rem;
    padding-top: 5.35714286rem;
}

.ui.inverted.loader {
    color: rgba(255, 255, 255, 0.9);
}
.ui.inverted.loader:before {
    border-color: rgba(255, 255, 255, 0.15);
}
.ui.inverted.loader:after {
    border-top-color: #FFFFFF;
}

.ui.inline.loader {
    position: relative;
    vertical-align: middle;
    margin: 0em;
    left: 0em;
    top: 0em;
    -webkit-transform: none;
    transform: none;
}
.ui.inline.loader.active,
.ui.inline.loader.visible {
    display: inline-block;
}

/* Centered Inline */
.ui.centered.inline.loader.active,
.ui.centered.inline.loader.visible {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.ui.button.white{
    border: 1px solid white;
    background: white;
    color: #141419;
}

.ui.button.white:hover, .ui.button.white:focus{
    background:transparent;
    color: white;
}

body {
    font-family: "IBM Plex Sans", sans-serif;
}

.ui.button {
    font-weight: 400 !important;
    transition: all 0.3s ease-out !important;
}

.ui.button:hover {
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .14);
}

.ui.button.small {
    padding-top: 11px !important;
    padding-bottom: 11px !important;
}

.ui.label {
    font-weight: 400 !important;
    line-height: 1.4;
}

dd, dl {
    margin: 0;
}

body h1,
body h2,
body h3,
body h4,
body h5,
body {
    color: #454555;
    font-weight: 400;
}

/*body .ui.button.primary,*/
/*body .ui.button.blue {*/
/*    background: #1E2744;*/
/*}*/

body .ui.button.primary .icon:before,
body .ui.button.blue .icon:before {
    color: white;
}

h1, h2, h3, h4, h5 {
    font-family: "IBM Plex Sans", sans-serif;
}

@media (max-width: 460px) {
    .ui.doubling.cards > .ui.card {
        width: 100%;
    }

    .ui.page.modals.dimmer.transition.visible.active{
        padding:0;
    }

    .ui.fullscreen.scrolling.modal.transition.visible.active{
        top:0;
        right:0;
    }

}




@media (max-width: 768px) {
    .ui.doubling.cards{
        display:flex !important;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .ui.doubling.cards > .ui.card {
        width: calc(50% - 0.5em) !important;
        margin: 0.25em !important;
        margin-top: 0 !important;
        margin-bottom: 0.5em !important;
    }
}

.ui.dimmer {
    background: rgba(5, 5, 35, 0.70);
}


@media only screen and (max-width: 991px) and (min-width: 768px) {
    .ui.container {
        width: calc(100% - 3rem);
    }
}

@media only screen and (min-width: 1400px) {
    .ui.container {
        width: 1330px;
    }
}

@media only screen and (min-width: 1450px) {
    .ui.container {
        width: 1440px;
    }
}


.ui.cards {
    margin: 0 -0.5em;
}
.ui.two.cards > .card.wide {
    width: 100%;
}