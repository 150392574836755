
.TireCardCompact.card  .BadgeAvailability .label.ribbon.availability {
    border-radius:0;
    right: 0;
    margin-right:10px;
    bottom:10px;
    text-align:center;
    padding:8px 12px;
    transform: none;
    left: auto;
    position: absolute;
}

.TireCardCompact.card .label.ribbon.availability:after {
    display: none;
}


.BadgeAvailability .label.ribbon.availability {
    border-radius:0;
    right: 0;
    margin-right:10px;
    text-align:center;
    padding:8px 12px;
    transform: none;
    left: auto;
    position: relative;
}

 .label.ribbon.availability:after {
    display: none;
}