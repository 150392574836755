
.CartPreview {
}

.CartPreview {
}

#ncg-app  table.ui.CartPreviewTable tr.skinny {
    border-bottom: 0;
}

#ncg-app  table.ui.CartPreviewTable tr.skinny td {
    border-top: 0;
    padding: 3px 0;
}

#ncg-app  table.ui.CartPreviewTable tr.skinny.extra-skinny td {
    border-top: 0;
    padding: 0 0;
    line-height: 0;
}


.CartPreviewMinimal.cart-type-TYR img{
    max-width:120px !important;
    padding-top:20px;
}

.CartPreviewMinimal.cart-type-SRV img{
    max-width:100% !important;
    padding-top:0;
}
