

.FooterGrey{

}

.BrandSelectionGrey a{
  opacity: 0.5;
  transition: opacity 320ms ease-out;
}

.BrandSelectionGrey a.active{
  opacity:1;
}

.BrandSelectionGrey a:hover{
  transform: translateY(2px);
}


.Footer {
  background:#212738;
  color: rgba(255,255,255,0.7);
}


#root .Footer .column{
  padding-bottom: 42px !important;
}
