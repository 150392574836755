
.QuickOrderSearchBar{
    @apply w-full;
}


#root .QuickOrderSearchBar input.prompt{
    @apply w-full p-6 pl-16 rounded-xl text-base md:text-lg font-bold bg-white shadow-sm !important;
    border: none !important;
}


#root .QuickOrderSearchBar input.prompt::placeholder{
    font-weight: normal !important;
}

#root .QuickOrderSearchBar .results.transition{
    @apply w-full absolute bg-white rounded-xl shadow-xl mt-2 !important;
    top: 100%;
    left: 0;
    z-index: 100;
    overflow-y: auto;
    overflow-x: hidden;
    border: 1px solid #E5E5E5;
    border-top: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding: 0;
    margin-bottom: 1px;
}