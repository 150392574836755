
.ShopSidebar.ShopSidebarLeft {
    display: block;
}

.ShopSidebar.optional {
    display: none;
}

.ShopContainer {
    min-height: calc(100vh - 226px);
}

.BottomDrawer {
    z-index: 990;
}

html body#root iframe#LeadboosterContainer {
    z-index: 991 !important;
}

.ShopSidebarLeft.off {
    transform: translateY(100vh);
}

.SidebarHeader,
.SidebarFooter {
}

.ShopContainer {
}

.ShopContainer.loading {
    pointer-events: none;
    opacity: 0.7;
}

.HomeLink img {
    filter: brightness(0) invert(1);
}


@media (min-width: 1200px) {
    .ShopContent {
        max-width: calc(100vw);
    }
}

.MobileDrawer .NavLink * {
    color: #8e8e8e;
    transition: all 500ms ease-out;
}

.MobileDrawer .NavLink.active * {
    color: white;
}

.MobileDrawer {
    box-shadow: 0px -10px 19px -8px rgba(0, 0, 0, 0.22);
    border-top: 4px solid rgba(125, 127, 132, 0.75);
}

.MobileDrawer .underline {
    position: absolute;
    top: -4px !important;
    left: 0;
    right: 0;
    bottom: auto;
    height: 4px;
    background: rgb(255, 255, 255);
}

@media (min-width: 480px) {

    .ShopContainer.isNonFluid .ShopContent{
        min-width:0;
        flex: 1;
        max-width:1024px;
    }

    .ShopContainer.isFluid .ShopContent{
        max-width:1280px;
    }

    .ShopSidebarLeft {
        position: relative;
        transform: none !important;
        padding: 0;
        background: transparent;
        height: auto;
    }
}

/*SMALL */
@media only screen and (max-width: 30em) {

    .SidebarHeader,
    .SidebarFooter {
        display: block;
    }

    body.sidebar-left-on {
        overflow: hidden !important;
    }

    html body#root iframe#LeadboosterContainer {
        z-index: 888 !important;
        bottom: 52px !important;
        display: none !important;
    }

    html body#root iframe#LeadboosterContainer.openedChat {
        z-index: 1000 !important;
        top: 0 !important;
        bottom: 5.825rem !important;
        height: calc(100% - 5.825rem) !important;
        display: block !important;
    }

    body.sidebar-left-on .ShopSidebar.ShopSidebarLeft {
        display: block;
        z-index: 10002; /*ABOVE BOTTOM DRAWER*/
    }

    body.sidebar-left-on .ShopSidebarLeft .SidebarHeader {
    }

    body.sidebar-left-on .ShopSidebarLeft .SidebarContent {
        padding-bottom: 80px;
        overflow-y: auto;
    }

    body.sidebar-left-on .ShopSidebarLeft .SidebarFooter {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
    }

    body.sidebar-left-on .ShopSidebarLeft {
        transition: transform 250ms ease-out;
        transform: translateY(0);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 0;
        background: white;
        z-index: 1001;
    }


}

/*SMALL + MEDIUM */
@media only screen and (max-width: 60em) {
    html body iframe#LeadboosterContainer {
        display: none !important;
    }

    html body iframe#LeadboosterContainer.openedChat {
        display: block !important;
    }

    body.sidebar-left-on .ShopSidebar.ShopSidebarLeft {
        display: block;
        z-index: 10002; /*ABOVE BOTTOM DRAWER*/
    }

    body .ShopSidebar.ShopSidebarLeft {
        display: none;
    }
}

/* NOT SMALL*/
@media only screen and (min-width: 30em) {
}

/*MEDIUM -m */
@media only screen and (min-width: 30em) and (max-width: 60em) {
    #root .ShopSidebarRight {
        display: none;
    }
}

/* LARGE -l*/
@media only screen and (min-width: 60em) {

}

/* LARGER (NOT IN TACHONYS) ~ 1280 === 80em at 16px font size*/
@media only screen and (min-width: 1280px) {
    .ShopSidebar.optional {
        display: block;
    }
}


/* XLARGE -l */
@media only screen and (min-width: 96em) {
}


@media only screen and (max-width: 768px) {


    .HomeLink {
        text-align: center;

    }


    .LogoImage {
        filter: brightness(0) invert(1);
        width: auto !important;
        height: auto !important;
    }

    .ShopContainer {
        flex-direction: column !important;
    }

    .ShopContainer .ShopContent .ui.container {
        margin: 0 !important;
    }


    .ShopSidebar.ShopSidebarRight {
        display: block;
    }

    #root .ShopSidebar.ShopSidebarRight {
        border-top: 1px solid #e1e1e4;
        margin-top: 1em;
        padding-top: 2em;
        max-width: 100% !important;
    }

    #root .ShopContainer {
        padding-left: 0;
        padding-right: 0;
        margin: 0 !important;
    }
}


.ShopSidebar.ShopSidebarContext.ShopSidebarLeft {
    max-width: 100% !important;
}


@media only screen and (min-width: 520px) and (max-width: 768px) {

    .ShopSidebar.ShopSidebarContext.ShopSidebarLeft {
        max-width: 100% !important;
        min-width: 100% !important;
    }

}

@media only screen and (min-width: 520px) and (max-width: 1035px) {
    .ShopContainer .ShopSidebar {
        max-width: 170px !important;
        min-width: 170px !important;
        position: relative;
    }


    .ShopSidebar.ShopSidebarContext.ShopSidebarLeft {
    }

}


@media only screen and (max-width: 1220px) {
    .ShopSidebar.ShopSidebarRight {
        display: none;
    }
}

/* XLARGE -l */
@media only screen and (min-width: 1024px) and (max-width: 1330px) {
    .ShopSidebar.ShopSidebarContext.ShopSidebarLeft {
    }

    .ShopContainer .ShopSidebar {
        min-width: 180px !important;
        max-width: 200px !important;
    }
}


@media only screen and (min-width: 60em) {
    #root .ShopSidebar.ShopSidebarLeft {
        display: block;
    }
}


@media only screen and (min-width: 1279px) {
    .ShopSidebar {
        display: block;
    }
}

.ShopContainer {
    flex-basis: 0;
}

.ShopContainer .ui.container {
    min-width: auto !important;
    max-width: 100% !important;
    width: 100%;
}

.ShopContainer .ShopSidebar {
    min-width: 212px;
    position: relative;
}

.ShopContainer .ShopContent {
    width: 100%;
}

@media (min-width: 1600px) {

    .ui.container.ShopContainer {
    }

    #root .ShopSidebarLeft {
    }
}
