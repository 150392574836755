.ui.cards > .card.AppointmentCard {

    transition: background-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.ui.cards > .card.AppointmentCard:hover {
    cursor: pointer;
}

.ui.cards .AppointmentCard {
    min-width: 180px;
}
